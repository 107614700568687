import React, { useEffect, useState } from "react"

import Swiper from "react-id-swiper"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import CampForm from "../forms/campForm"
import Video from "../components/Video/Video"

import "swiper/swiper.scss"
import "./event.scss"

const EventTemplate = ({ data, location }) => {
  const event = data.cockpitEvents

  const [previewSwiper, getPreviewSwiper] = useState(null)
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null)
  const previewSwiperParams = {
    getSwiper: getPreviewSwiper,
    spaceBetween: 20,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }
  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 10,
    slidesPerView: "auto",
    centeredSlides: true,
    slideToClickedSlide: true,
    freeMode: true,
    freeModeSticky: true,
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
    },
  }
  useEffect(() => {
    if (
      previewSwiper !== null &&
      previewSwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      previewSwiper.controller.control = thumbnailSwiper
      thumbnailSwiper.controller.control = previewSwiper
      previewSwiper.update()
    }
  }, [previewSwiper, thumbnailSwiper])

  return (
    <Layout>
      <SEO
        title={event.title.value}
        description={event.summary.value}
        image={event.title_image.value.publicURL}
        pathname={location.pathname}
        article="true"
      />
      <div className="eventTemplate">
        <figure className="title-image image is-3by1 super-size-image">
          <Img
            fluid={event.title_image.value.childImageSharp.fluid}
            className="fit-image"
          />
        </figure>
        <section className="">
          <div className="container">
            <main className="content">
              {/* --- Title --- */}
              <h1 className="title is-1 has-text-centered">
                {event.title.value}
              </h1>
              {/* Date */}
              <p className="date has-text-grey-light has-text-centered">
                vom {event.start_date.value} bis {event.end_date.value}
              </p>
              {/* Intro */}
              {event.introduction && (
                <p className="introduction is-size-4">
                  {event.introduction.value}
                </p>
              )}
              {/* Description */}
              <p className="has-text-justified">{event.description.value}</p>

              {/* --- GALERY --- */}
              {event.image_gallery && (
                <>
                  <h2>Galerie</h2>
                  <div className="gallery">
                    <div className="preview-swiper">
                      <Swiper {...previewSwiperParams}>
                        {event.video && (
                          <div className="preview-swiper-image">
                            <Video
                              src={`https://www.youtube-nocookie.com/embed/${event.video.value}`}
                            ></Video>
                          </div>
                        )}
                        {event.image_gallery.value.map(value => (
                          <Img
                            fluid={value.childImageSharp.fixed}
                            className="preview-swiper-image"
                            imgStyle={{ objectFit: "contain" }}
                          />
                        ))}
                      </Swiper>
                    </div>
                    <div className="thumbnail-swiper">
                      <Swiper {...thumbnailSwiperParams}>
                        {event.video && (
                          <div className="thumbnail-swiper-slide">
                            <img
                              className="thumbnail-swiper-image"
                              src={`https://img.youtube.com/vi/${event.video.value}/default.jpg`}
                            ></img>
                          </div>
                        )}
                        {event.image_gallery.value.map(value => (
                          <div className="thumbnail-swiper-slide">
                            <Img
                              fluid={value.childImageSharp.fixed}
                              className="thumbnail-swiper-image"
                            />
                          </div>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </>
              )}
              {/* End */}
              {event.end && (
                <p className="has-text-justified">{event.end.value}</p>
              )}

              {/* Extra */}
              {event.extra && (
                <p className="has-text-justified">
                  <h2>Kleingedrucktes</h2>
                  {event.extra.value}
                </p>
              )}
              {/* Form */}
              {event.register_form_typ && (
                <p>
                  <h2>Anmeldung</h2>
                  <CampForm />
                </p>
              )}
            </main>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query fullEvent($slug: String) {
    cockpitEvents(slug: { value: { eq: $slug } }) {
      start_date {
        value
      }
      end_date {
        value
      }
      title {
        value
      }
      summary {
        value
      }
      introduction {
        value
      }
      description {
        value
      }
      end {
        value
      }
      extra {
        value
      }
      video {
        value
      }
      title_image {
        value {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      image_gallery {
        value {
          publicURL
          childImageSharp {
            fixed(width: 1056) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      register_form_typ {
        value
      }
    }
  }
`

export default EventTemplate
